.form {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  height: auto;
}

.input-container {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
}

.input {
  flex-grow: 1;
  padding: 0.75rem 1.5rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  font-size: 1rem;
  margin: .5rem;
}

.select-container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  gap: 1rem;
  padding: 0.5rem;
  width: 100%;
}

.select, .querybutton {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.select {
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  font-size: 1rem;
  background-color: white;
  padding: 0.75rem 1rem;
  margin-right: 0.5rem;
}

.querybutton {
  font-size: 1rem;
  background-color: #1c6dad;
  color: white;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.hero-image {
  display:none;
}

@media screen and (min-width: 1200px) {
  .form {
    flex-direction: row;
    gap: 20px;
  }

  .form-content {
    flex: 2;
    max-width: 700px;
  }

  .hero-image {
    display: block;
    flex: 1; 
    max-width: 500px;
  }

  .select-container {
    min-width: auto;
  }
}
