.bimi-container {
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  max-width: 600px;
  margin: 2rem auto;
}

.bimi-title {
  font-size: 1.75rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 1.5rem;
}

.bimi-input,
.bimi-result {
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  color: #333;
  margin-bottom: 1rem;
}

.bimi-label {
  font-weight: 500;
  margin-right: 0.5rem;
  min-width: 110px;
}

.bimi-value {
  font-weight: 400;
  word-break: break-word;
}

.bimi-logo-container {
  display: flex;
  justify-content: space-around;
  margin-top: 1rem;
  background-color: #f0f0f0;
  padding: 1rem;
  border-radius: 4px;
}

.bimi-logo {
  height: 144px;
  width: 144px;
  margin: 0 1rem;
  object-fit: cover;
  background-color: white;
}

.bimi-logo.dark-mode {
  background-color: #333;
}

.bimi-logo-square {
  border-radius: 4px;
}

.bimi-logo-circle {
  border-radius: 50%;
}

.bimi-logo-squircle {
  border-radius: 20%;
}

.bimi-search {
  display: flex;
  margin-top: 1rem;
}

.bimi-search-input-wrapper {
  margin-right: 1rem;
}

.bimi-search-button-wrapper {
  display: flex;
  align-items: center;
}

.bimi-toggle-dark-mode,
.bimi-download-button,
.bimi-refresh-button,
.bimi-search-button {
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.bimi-search-button {
  margin-top: 0;
  margin-left: 8px;
  height: 38px;
  line-height: 22px;
}

.bimi-toggle-dark-mode:hover,
.bimi-download-button:hover,
.bimi-refresh-button:hover,
.bimi-search-button:hover {
  background-color: #0056b3;
}

.bimi-container input::placeholder {
  color: #999;
}

.dark-mode {
  background-color: #333;
}

.bimi-warning,
.bimi-logo-warning {
  color: red;
  font-weight: bold;
  margin-top: 1rem;
}