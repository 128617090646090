@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@400;500&display=swap');
html {
  margin: 0;
  height: 100% 
}
body {
  font-family: "Poppins", sans-serif;
  background-color: #f5f5f5;
  color: #333;
  margin: 0;
  line-height: 1.6;
  font-size: 20px;
  font-weight: 400;
  height: 100%;
}


h1, h2, h3, h4, h5, h6 {
  font-family: "Lato", sans-serif;
  font-weight: 700;
}

a {
  color: #1c6dad;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.button {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  background-color: #1c6dad;
  color: white;
  border-radius: 0.25rem;
  border: none;
  cursor: pointer;
  margin-bottom: 0.5rem;
  margin-right: 1rem;
}

.ip-banner-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.logout-button {
  background-color: #0056b3;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  text-decoration: none;
  display: inline-block;
  transition: background-color 0.3s, color 0.3s;
}

.logout-button:hover {
  background-color: #003f7f;
  text-decoration: none;
}

.ip-text {
  margin: 0;
  display: flex;
  align-items: center;
  margin-right: 1rem;
}

.auth-container {
  display: flex;
  align-items: center;
}

.login-button {
  background-color: #f1c40f;
  color: #1c6dad;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  text-decoration: none;
  display: inline-block;
  transition: background-color 0.3s, color 0.3s;
}

.login-button:hover {
  background-color: #d4ac0d;
  color: white;
  text-decoration: none;
}