.about-content {
    font-family: Arial, sans-serif;
    color: #333;
    padding: 20px;
    max-width: 800px;
    margin: auto;
  }
  
  .section-title {
    color: #0056b3;
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .intro-text {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .category {
    background-color: #f9f9f9;
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 5px;
  }
  
  .category-title {
    color: #004085;
    font-size: 20px;
    margin-bottom: 5px;
  }
  
  .category-description {
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  .link-list {
    list-style-type: none;
    padding: 0;
  }
  
  .link-list li {
    margin-bottom: 5px;
  }
  
  .link-list a {
    color: #007bff;
    text-decoration: none;
  }
  
  .link-list a:hover {
    text-decoration: underline;
  }
  